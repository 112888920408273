const setCookie = (name, value, days) => {
  const exp = new Date()
  exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${escape(
    value
  )};expires=${exp.toGMTString()};path=/`
}

const getCookie = (name) => {
  let arr
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`)
  if (document.cookie.match(reg)) {
    arr = document.cookie.match(reg)
    return unescape(arr[2])
  }
  return null
}
const deleteCookie = (name) => {
  setCookie(name, null, -1)
}
export { setCookie, getCookie, deleteCookie }
