import React, { useEffect } from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import {
  saveToken,
  getToken,
  getProjectMsg,
  refreshCompanyId,
  saveCompanyId,
  clearCompanyId,
  getCurrentCompanyId,
  getProjectId,
} from 'jcb-token-kit'
import NavMenu from 'jcb-nav-menu'
import { Spin } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'
import less from 'less'
import { getAsyncComponent, isNeedLoginPage, isLoginPage } from '@utils'
import { JcbName } from '@utils/constant'
import { getMainProjectId } from '@utils/project'
import favicon from '../../assets/images/favicon.ico'

const { useCustomized, usePage, useDomain, useCache } = NavMenu
const Layout = ({ location, history }) => {
  const { gotoLoginPage, getCompanyInfo } = useDomain()
  const {
    isLoading,
    getData,
    resources,
    getCompanySign,
    loadPage,
    clearCustomizedData,
  } = useCustomized()
  const { updateTitle, updateFavicon, updateThemeColor } = usePage()
  const { updateProjectName } = useCache()

  const { token, companyid } = qs.parse(document.location.search.split('?')[1])
  if (token) {
    saveToken()
    if (companyid && companyid !== 0 && companyid !== '0') {
      refreshCompanyId()
    }
    if (companyid || companyid === 0 || companyid === '0') {
      // 为了判断一下是否是责任单位端
      sessionStorage.removeItem('login_from_jcb')
    }
  }
  if (!token) {
    if (companyid && companyid !== 0 && companyid !== '0') {
      refreshCompanyId()
    }
  }
  if (getToken()) {
    axios.defaults.headers.common.token = getToken()
  } else if (token) {
    axios.defaults.headers.common.token = token
  }

  useEffect(() => {
    // sessionStorage.removeItem('projectName')
    localStorage.removeItem('dygchome')
  }, [])

  useEffect(() => {
    if (location.pathname === '/') {
      if (getToken()) {
        getProjectMsg().then(({ data }) => {
          const { organizations = [] } = _.get(data, 0, {})
          const roleSlug = _.get(organizations, '0.roleSlug', '')
          sessionStorage.setItem('role', roleSlug)
          setTimeout(() => {
            if (
              localStorage.getItem('loginCompanyid') &&
              !companyid &&
              localStorage.getItem('isSpecial')
            ) {
              // 通过定制企业的主项目进入工作台
              const id = getMainProjectId(
                localStorage.getItem('loginCompanyid')
              )
              if (id) {
                history.push(`/projects/${id}/workspace`)
              }
            } else {
              history.push('/dashboard')
            }
          }, 10)
        })
      }
    }
  }, [])

  useEffect(() => {
    if (!companyid && companyid !== 0 && token) {
      getCurrentCompanyId().then((data) => {
        if (data.id) {
          refreshCompanyId(data.id)
          getData()
          getCompanySign()
        } else {
          getData()
        }
      })
    } else {
      if (isLoginPage() && !companyid && companyid !== 0) {
        localStorage.removeItem('loginCompanyid')
        clearCompanyId()
        clearCustomizedData()
      } else if (!companyid && companyid !== 0) {
        loadPage()
      }
      // getData()
      getCompanySign()
    }
    // 项目相关
    if (getProjectId()) {
      updateProjectName(getProjectId())
    }
  }, [])

  useEffect(() => {
    if (companyid || companyid === 0) {
      if (companyid === 0 || companyid === '0') {
        clearCompanyId()
        getCompanyInfo().then((res) => {
          saveCompanyId(7, res.id + '')
          getData()
          getCompanySign()
        })
      } else {
        getData()
        getCompanySign()
      }
    }
  }, [companyid])

  useEffect(() => {
    if (isLoading) {
      if (!isNeedLoginPage() && !getToken()) {
        if (document.location.hostname === 'yuanlin.oopsdata.com') {
          window.location.href = '/login'
        } else {
          const fun = gotoLoginPage()
          if (fun) {
            fun.then((href_) => {
              if (href_) {
                window.location.href = href_
              }
            })
          }
        }
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (isLoading) {
      console.log(Object.keys(resources).length > 0)
      if (localStorage.getItem('isSpecial')) {
        updateTitle()
        updateFavicon()
      } else {
        updateTitle(JcbName)
        updateFavicon(favicon)
      }
    }
  }, [isLoading])
  // const addCssByLink = () => {
  //   let ele = document.getElementById('theme_less')
  //   if (!ele) {
  //     ele = document.querySelector('link[rel="stylesheet/less"]')
  //   }
  //   ele.href = 'theme.less'
  // }
  useEffect(() => {
    if (process.env.REACT_APP_TYPE === 'city') {
      less.modifyVars({
        '@primary-color': '#bd192d',
      })
    } else if (isLoading) {
      // if (localStorage.getItem('themeColor')) {
      //   addCssByLink()
      //   less.modifyVars({
      //     '@primary-color': localStorage.getItem('themeColor'),
      //   })
      // }
      updateThemeColor()
    }
  }, [isLoading])

  return !isLoading ? (
    <Spin />
  ) : (
    <Switch>
      <Redirect from="/" to="/dashboard" exact />
      <Route
        path="/login"
        component={getAsyncComponent(() => {
          return import('../commonLogin/customLogin')
        })}
      />
      <Route
        path="/dashboard"
        component={getAsyncComponent(() => {
          return import('./DashboardLayout')
        })}
      />
      <Route
        path="/projects"
        component={getAsyncComponent(() => {
          return import('../../components/base/MainLayout')
        })}
      />
      <Route
        path="/business/statistics"
        component={getAsyncComponent(() => {
          return import('../Business/Statistics')
        })}
      />
      <Route
        path="/spec/projects/:id/standard"
        component={getAsyncComponent(() => {
          return import('../Standard')
        })}
      />
      <Route
        path="/screen/city"
        component={getAsyncComponent(() => {
          return import('../dlScreen/city')
        })}
      />
      <Route
        path="/screen/district"
        component={getAsyncComponent(() => {
          return import('../dlScreen/district')
        })}
      />
      <Route
        path="/screen/street"
        component={getAsyncComponent(() => {
          return import('../dlScreen/street')
        })}
      />
      <Route
        path="/screen/community"
        component={getAsyncComponent(() => {
          return import('../dlScreen/community')
        })}
      />
      <Route
        path="/caseLog"
        component={getAsyncComponent(() => import('../CaseLog'))}
      />
      {/* <Route
        path="/home"
        component={getAsyncComponent(() => import('../home/Index'))}
      /> */}
      <Route path="/app" component={getAsyncComponent(() => import('./App'))} />
      <Route
        path="/map"
        component={getAsyncComponent(() => import('../FreqPointMap/Map'))}
      />
      <Route path="/404" component={getAsyncComponent(() => import('./404'))} />
    </Switch>
  )
}

export default withRouter(Layout)
