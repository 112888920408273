import React, { useEffect } from 'react'
// import {
//   Form, Select, InputNumber, DatePicker, Switch, Slider, Button
// } from 'antd';
import '../styles/App.css'
import Layout from '@containers/common/Layout'
import { deleteCookie } from '@utils/useCookie'
import { LocaleProvider } from 'antd'
import { BrowserRouter as Router } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import '@apis/interceptors'
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'antd/dist/antd.less'
import ErrorPage from '@containers/common/ErrorPage'

moment.locale('zh-cn')

const ErrorFallback = ({ error }) => {
  return (
    <div role="alert">
      <ErrorPage txt="页面出现异常，请刷新重试" error={error.message} />
    </div>
  )
}

export default () => {
  useEffect(() => {
    document.onclick = () => {
      localStorage.setItem('userTime', new Date().getTime())
    }
    setInterval(() => {
      if (localStorage.getItem('userTime')) {
        if (
          new Date().getTime() - localStorage.getItem('userTime') >
          60000 * 30
        ) {
          deleteCookie('token')
          window.location.href = 'https://release-xc.survey.work/login'
        }
      }
    }, 60000)
  }, [])
  window.ty = {}

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LocaleProvider locale={zh_CN}>
        <Router>
          <Layout />
        </Router>
      </LocaleProvider>
    </ErrorBoundary>
  )
}
